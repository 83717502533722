/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://esxr3oma6fcynnkecs5rvb3tpi.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-shwuftgj3fetffm4tbprbvotca",
    "aws_cognito_identity_pool_id": "us-west-2:b4d62272-9bd8-4a4e-8bcc-86e91ff06af4",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_IN9SrQIak",
    "aws_user_pools_web_client_id": "qlnn5559at7hp008llcqjlorg",
    "oauth": {}
};


export default awsmobile;
