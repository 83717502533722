/* src/App.js */
import React, { useEffect, useState } from 'react'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { createTodo } from './graphql/mutations'
import { listTodos } from './graphql/queries'
import { withAuthenticator } from '@aws-amplify/ui-react'

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const initialState = { name: '', description: '' }

const App = () => {
  const [formState, setFormState] = useState(initialState)
  const [todos, setTodos, signedUrl] = useState([])

  useEffect(() => {
    fetchTodos()
  }, [])

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value })
  }

  async function fetchTodos() {
    try {
      const todoData = await API.graphql(graphqlOperation(listTodos))
      const todos = todoData.data.listTodos.items
      setTodos(todos)
    } catch (err) { console.log('error fetching todos') }
  }

  async function addTodo() {
    try {
      if (!formState.name || !formState.description) return
      const todo = { ...formState }
      setTodos([...todos, todo])
      setFormState(initialState)
      await API.graphql(graphqlOperation(createTodo, {input: todo}))
    } catch (err) {
      console.log('error creating todo:', err)
    }
  }



  async function testApi() {
      const apiName = 'HelloWorld'; // replace this with your api name.
      const path = '/Test'; //replace this with the path you have configured on your API
      const myInit = {
        body: {
          "key1": "value1",
          "key2": "value2",
          "key3": "value3"
        },
        headers: {}, // OPTIONAL
      };
      
      API
        .post(apiName, path, myInit)
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error.response);
        });  
      //return await API.post(apiName, path, myInit);
  }

  async function getSignedUrl() {
    const apiName = 'SignedUrl'; // replace this with your api name.
    const path = '/default/SignedURL'; //replace this with the path you have configured on your API
    const myInit = {
      body: {
        "ResourceUrl": "https://d989imbcz2mxx.cloudfront.net/SkipBarberTest/SkipBarberTest.m3u8",
        "IpAddress": "72.203.75.200",
        "Duration": 5
        },
      headers: {}, // OPTIONAL
    };
    
    API
      .post(apiName, path, myInit)
      .then(response => {
        console.log(response);
        signedUrl(response);
      })
      .catch(error => {
        console.log(error.response);
      });  
    //return await API.post(apiName, path, myInit);
}

  



  return (
    <div style={styles.container}>
      <h2>Live River Todos</h2>
      <input
        onChange={event => setInput('name', event.target.value)}
        style={styles.input}
        value={formState.name} 
        placeholder="Name"
      />
      <input
        onChange={event => setInput('description', event.target.value)}
        style={styles.input}
        value={formState.description}
        placeholder="Description"
      />
      <button style={styles.button} onClick={addTodo}>Create Todo</button>
      {
        todos.map((todo, index) => (
          <div key={todo.id ? todo.id : index} style={styles.todo}>
            <p style={styles.todoName}>{todo.name}</p>
            <p style={styles.todoDescription}>{todo.description}</p>
          </div>
        ))
      }
      <p><button style={styles.button} onClick={testApi}>Test New API</button></p>
      <p><button style={styles.button} onClick={getSignedUrl}>Get Signed URL</button></p>
      <p style={styles.todoDescription}>{signedUrl}</p>
    </div>
  )
}

const styles = {
  container: { width: 400, margin: '0 auto', display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', padding: 20 },
  todo: {  marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  todoName: { fontSize: 20, fontWeight: 'bold' },
  todoDescription: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

Amplify.configure({
    API: {
        endpoints: [
            {
                name: "HelloWorld",
                endpoint: "https://irj0t57fdi.execute-api.us-west-2.amazonaws.com"
            },
            {
              name: "SignedUrl",
              endpoint: "https://fw2q0fqquh.execute-api.us-west-2.amazonaws.com"
            }
        ]
    }
});

export default withAuthenticator(App)
//export default App